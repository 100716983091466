import React from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard11 from '../components/feature-card11'
import './parts.css'

const Parts = (props) => {
  return (
    <div className="parts-container">
      <Helmet>
        <title>Parts - Affla-Tech Maintenance Service</title>
        <meta
          property="og:title"
          content="Parts - Affla-Tech Maintenance Service"
        />
      </Helmet>
      <div className="navbar-container">
        <div className="max-width">
          <div className="parts-logo">
            <span className="parts-text">
              <span className="parts-text1">affla</span>
              <span className="parts-text2 brandName">Tech</span>
              <span className="brandName">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="brandName">|</span>
              <span className="brandName">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="parts-text6 brandName">PARTS</span>
            </span>
          </div>
          <div className="parts-links">
            <a
              href="https://booking.affla-tech.com"
              target="_blank"
              rel="noreferrer noopener"
              className="parts-link button button-primary"
            >
              Book Now
            </a>
          </div>
          <div className="parts-burger-menu navbar-burger-menu">
            <svg viewBox="0 0 1024 1024" className="parts-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="parts-features">
        <h1 className="parts-text7">Shop online for appliance parts</h1>
        <div className="parts-container1">
          <a
            href="https://amzn.to/48BavAm"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              rootClassName="rootClassName"
              className="parts-component"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3S1lkqi"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Samsung Fridge Defrost Temp Sensor"
              image_src1="/drain_heater-200h.jpg"
              rootClassName="rootClassName1"
              className="parts-component01"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3vpYEXB"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Samsung Fridge Drain Heater"
              image_src1="/whirlp_drain_heater-200h.jpg"
              rootClassName="rootClassName2"
              className="parts-component02"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3tGEiJ9"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Samsung Fridge Water Filter"
              image_src1="/sams_water_filt-200h.jpg"
              rootClassName="rootClassName3"
              className="parts-component03"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3NHROD6"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Surface Burner Whirlpool Electric Stove"
              image_src1="/surfburn-200w.jpg"
              rootClassName="rootClassName7"
              className="parts-component04"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3vlC96s"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Electric Stove Infinity Switch"
              image_src="/infiniswitch-200h.jpg"
              image_src1="/infiniswitch-200h.jpg"
              rootClassName="rootClassName6"
              className="parts-component05"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/4aGUoDx"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="GE Stove Clock"
              image_src1="/gestvclk-200h.jpg"
              rootClassName="rootClassName5"
              className="parts-component06"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3NIMteZ"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Whirlpool Washer Timer"
              image_src1="/whirl_timr-200h.jpg"
              rootClassName="rootClassName4"
              className="parts-component07"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3NOP2vZ"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Washing Machine Actuator"
              image_src1="/parts/actutr-200h.jpg"
              rootClassName="rootClassName8"
              className="parts-component08"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3NJxjG9"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Gas Oven Ignitor"
              image_src1="/parts/igntr-200w.jpg"
              rootClassName="rootClassName9"
              className="parts-component09"
            ></FeatureCard11>
          </a>
          <a
            href="https://amzn.to/3H22sRK"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FeatureCard11
              title="Gas Stove Oven Safety Valve"
              image_src1="/parts/sftyvlv-200w.jpg"
              rootClassName="rootClassName10"
              className="parts-component10"
            ></FeatureCard11>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Parts
