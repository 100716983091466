import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Affla-Tech Maintenance Service</title>
        <meta property="og:title" content="Affla-Tech Maintenance Service" />
      </Helmet>
      <header data-thq="thq-navbar" className="home-navbar-interactive">
        <div className="home-logo">
          <span className="home-text">
            <span className="home-text01">affla</span>
            <span className="home-text02 brandName">Tech</span>
          </span>
        </div>
        <div data-thq="thq-navbar-nav" className="home-desktop-menu"></div>
        <div className="home-max-width max-width"></div>
        <div className="home-links">
          <Link to="/parts" className="home-shop-parts navbarLink">
            Shop Parts
          </Link>
          <a href="#howitworks" className="home-link navbarLink">
            How it works
          </a>
          <a
            href="https://afflatech.setmore.com"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link1 button button-primary"
          >
            Book Now
          </a>
        </div>
        <div data-thq="thq-burger-menu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div className="home-nav">
            <div className="home-top">
              <span className="home-text03">
                <span className="home-text04">affla</span>
                <span className="home-text05 brandName">Tech</span>
              </span>
              <div data-thq="thq-close-menu" className="home-close-menu">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="home-links1">
              <Link to="/parts" className="home-navlink">
                Shop Parts
              </Link>
              <button type="button" className="home-button button">
                BOOK NOW
              </button>
            </nav>
          </div>
        </div>
      </header>
      <div className="hero-container section-container">
        <div className="home-max-width1 max-width">
          <div className="home-content">
            <span className="home-subtitle beforeHeading">
              affla-tech maintenance service
            </span>
            <h1 className="home-title">
              <span>
                Jamaica&apos;s
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text07">highest rated</span>
              <span> appliance repair service.</span>
            </h1>
            <span className="home-description">
              Trust your domestic, commercial and industrial appliance repairs,
              servicing and maintenance to the best.
            </span>
            <div className="home-container1">
              <a
                href="https://afflatech.setmore.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link2 button button-transparent"
              >
                Book Now
              </a>
            </div>
          </div>
          <div className="home-image">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1584722905423-34d90dd2120b?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI1fHxzdG92ZXxlbnwwfHx8fDE2NjgwNDY3NDg&amp;ixlib=rb-4.0.3&amp;w=600"
              className="home-hero-image"
            />
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="home-max-width2 max-width">
          <div className="home-image1">
            <div className="home-container2">
              <div className="home-container3">
                <Script
                  html={`<iframe
    style="border: none !important; margin: 0px !important; padding: 0px !important; width: 100% !important; min-width: 300px !important; display: block !important; height: 100% !important; user-select: none !important; min-height: 250px !important; z-index: 9999999;"
    src="https://app.cloutly.com/widget-cloutly/4fe28050-ac69-4b8b-80f1-46246523fdc5"></iframe>`}
                ></Script>
              </div>
            </div>
          </div>
          <div className="home-content1">
            <span className="home-text09 beforeHeading">TESTIMONIALS</span>
            <h1 className="home-text10">
              <span>Don&apos;t take </span>
              <span className="home-text12">our </span>
              <span>word for it.</span>
            </h1>
            <span className="home-text14">
              Dozens of satisfied customers can&apos;t be wrong. Before the
              moment you even pick up the phone, you can be sure you&apos;ve
              made the right choice for your appliances.
            </span>
          </div>
        </div>
      </div>
      <div id="howitworks" className="home-section1 section-container">
        <div className="home-max-width3 max-width">
          <div className="home-image2">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1535104631072-f0e4cab96dbd?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxibGFjayUyMGhhbmQlMjBob2xkaW5nJTIwcGhvbmV8ZW58MHx8fHwxNjY4NzM3MzI2&amp;ixlib=rb-4.0.3&amp;w=1200"
              className="home-hero-image1"
            />
          </div>
          <div className="home-content2">
            <span className="home-text15 beforeHeading">
              getting your appliance repaired
            </span>
            <h1 className="home-text16">How it works</h1>
            <div className="home-step">
              <div className="home-number">
                <span className="home-text17">1</span>
              </div>
              <div className="home-container4">
                <span className="home-title1">Give us a call</span>
                <span className="home-text18">
                  Call us at (876)-820-9568 from 8:30am to 5pm Monday to Friday.
                  Be sure to tell us what&apos;s wrong and where it&apos;s
                  located. We&apos;ll work with you to find an appointment time
                  and date that works best.
                </span>
              </div>
            </div>
            <div className="home-step1">
              <div className="home-number1">
                <span className="home-text19">2</span>
              </div>
              <div className="home-container5">
                <span className="home-title2">We assess it</span>
                <span className="home-text20">
                  A technician will visit you and give a full assessment on what
                  exactly is wrong with your appliance, including estimated
                  parts and labour costs. Assessment cost is $4000.
                </span>
              </div>
            </div>
            <div className="home-step2">
              <div className="home-number2">
                <span className="home-text21">3</span>
              </div>
              <div className="home-container6">
                <span className="home-title3">We repair it</span>
                <span className="home-text22">
                  Our skilled technicians then carefully repair your appliance
                  and you join our family of satisfied customers. 
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 section-container">
        <div className="home-max-width4 max-width">
          <div className="home-faq">
            <div className="home-questions">
              <span className="home-text23 beforeHeading">faq</span>
              <h1 className="home-text24">
                <span className="home-text25">
                  Frequently Asked
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span className="home-text27">Questions</span>
              </h1>
              <div data-role="Accordion" className="home-question question">
                <div data-type="accordion-header" className="home-trigger">
                  <span className="home-text28">
                    What appliances do you repair?
                  </span>
                  <svg viewBox="0 0 1024 1024" className="home-icon04">
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                </div>
                <div
                  data-type="accordion-content"
                  className="home-answer question-content"
                >
                  <span className="home-text29">
                    We repair, service, and install residential, commercial and
                    industrial refrigerators, freezers, stoves, washing
                    machines, dryers, air conditioning units, cold rooms and
                    reefer containers.
                  </span>
                </div>
              </div>
              <div data-role="Accordion" className="question">
                <div data-type="accordion-header" className="home-trigger1">
                  <span className="home-text30">
                    Where do you operate from?
                  </span>
                  <svg viewBox="0 0 1024 1024" className="home-icon06">
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                </div>
                <div
                  data-type="accordion-content"
                  className="home-answer1 question-content"
                >
                  <span className="home-text31">
                    We are 100% mobile and operate within the Kingston and St.
                    Andrew corporate area. No need to worry about transporting
                    your appliances anywhere as our technicians come to you.
                  </span>
                </div>
              </div>
              <div data-role="Accordion" className="question">
                <div data-type="accordion-header" className="home-trigger2">
                  <span className="home-text32">Do you go out of town?</span>
                  <svg viewBox="0 0 1024 1024" className="home-icon08">
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                </div>
                <div data-type="accordion-content" className="question-content">
                  <span className="home-text33">
                    <span>
                      At this time we do not repair appliances outside of
                      Kingston and St. Andrew.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div data-role="Accordion" className="question">
                <div data-type="accordion-header" className="home-trigger3">
                  <span className="home-text36">
                    How much will it cost to repair my appliance?
                  </span>
                  <svg viewBox="0 0 1024 1024" className="home-icon10">
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                </div>
                <div data-type="accordion-content" className="question-content">
                  <span className="home-text37">
                    Appliance repair cost varies depending on the severity of
                    the issue as well as what parts (if any) it will need and
                    the tecnician will inform you as much. However we do charge
                    a $4000 assessment fee.
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1577553698923-17f1a80ce5bc?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHdhc2hpbmclMjBtYWNoaW5lfGVufDB8fHx8MTY2ODczNzM2Nw&amp;ixlib=rb-4.0.3&amp;w=1200"
              className="home-image3"
            />
            <div>
              <div className="home-container8">
                <Script
                  html={`<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8071902571018020"
    crossorigin="anonymous"></script>`}
                ></Script>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
